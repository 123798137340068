import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import './UserDropdown.css';
import ImageBox from '../../Basic/ImageBox/ImageBox';
import TextBox from '../../Basic/TextBox/TextBox';

import { finalizePendingActionsAndLogout } from '../../../Util/Helper/UserWorkplaceUtil';
import { isBusinessLicense } from '../../../Util/Helper/LicenseUtil';

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  currentWorkplace: state.navigation.currentWorkplace
});

const UserDropdown = ({ currentUser, currentWorkplace }) => {
  const { logout } = useAuth0();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef();
  const history = useHistory();

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen)
  }

  const handleClickOutside = (event) => {
    if (dropdownOpen && !dropdownRef.current.contains(event.target)) {
      toggleDropdown();
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  return (
    <div className="UserDropdown" ref={dropdownRef}>
      <button
        type="button"
        className="ButtonBox-UserDropdown"
        onClick={() => toggleDropdown()}
      >
        <div className="UserDropdown-name">
          <ImageBox className="Profile-Pic" src={currentUser.image_url || 'default_user.png'} />
        </div>
      </button>

      {
        dropdownOpen
        && (
          <div className="UserDropdown-dropdown">
            <div className="Profile-Section">
              <ImageBox className="Profile-Pic" src={currentUser.image_url || 'default_user.png'} />
              <TextBox className="User-Name" text={`${currentUser.first_name} ${currentUser.last_name}`} tag="h5" />
            </div>

            {
              (!['TE.Admin', 'B2B.Admin'].includes(currentUser.user_type)) && (!isBusinessLicense(currentWorkplace?.license_type)) &&
              (
                <div className="Link">
                  <Link to="/account/learn/course_packages">
                    Enroll
                  </Link>
                </div>
              )
            }

            {
              (['B2C.User', 'B2B.User'].includes(currentUser.user_type)) &&
              <>
                <div className="Link">
                  <Link to="/learn">
                    Learn
                  </Link>
                </div>

                <div className="Link">
                  <Link to="/account/profile">
                    My Profile
                  </Link>
                </div>

                <div className="Link">
                  <Link to="/account/analyze">
                    My Progress
                  </Link>
                </div>

                <div className="Link">
                  <Link to="/account/certificate">
                    My Certificates
                  </Link>
                </div>
              </>
            }

            {
              (currentUser.user_type === 'B2B.Admin') &&
              <>
                <div className="Link">
                  <Link to="/account/search">
                    Add/Search Users
                  </Link>
                </div>
                <div className="Link">
                  <Link to="/account/progress">
                    Generate Reports
                  </Link>
                </div>
                <div className="Link">
                  <Link to="/account/groups">
                    Manage Groups
                  </Link>
                </div>
                <div className="Link">
                  <Link to="/account/profile">
                    My Account
                  </Link>
                </div>
              </>
            }

            {
              currentUser.user_type === "TE.Admin"
              && (
                <>
                  <div className="Link">
                    <Link to="/creation">
                      Create
                    </Link>
                  </div>
                  <div className="Link">
                    <Link to="/learn">
                      Preview
                    </Link>
                  </div>
                </>
              )
            }

            <div className="Link">
              <Link
                to="/account/learn"
                onClick={() => finalizePendingActionsAndLogout(history, logout)}
              >
                Log Out
              </Link>
            </div>
          </div>
        )
      }
    </div>
  );
}

export default connect(mapStateToProps, null)(UserDropdown);
