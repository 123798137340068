import React, { useEffect, useState, useRef } from 'react';
import RecordRTC from 'recordrtc';
import ReactTooltip from 'react-tooltip';

import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import InformationModal from '../../Composed/InformationModal/InformationModal';
import AudioPlayButton from '../AudioPlayButton/AudioPlayButton';
import AudioAnalyzeButton from '../AudioAnalyzeButton/AudioAnalyzeButton';

import { gaEvent } from '../../../Util/Helper/GoogleUtil';

import './AudioRecordButton.css';

const AudioRecordButton = ({ onRecordFinished, onPlayFinished, language, text }) => {
  const [permissionDenied, setPermissionDenied] = useState(false);
  const [recorder, setRecorder] = useState({ isRecording: false, blob: null, url: null });

  const recRTCRef = useRef({ stream: null, instance: null });

  useEffect(() => {
    return () => {
      recRTCRef.current.instance?.stopRecording();
      recRTCRef.current.stream?.getTracks()?.forEach(track => track.stop());
    }
  }, []);

  const handleStartRecording = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      recRTCRef.current.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      recRTCRef.current.instance = new RecordRTC(recRTCRef.current.stream, {
        type: 'audio',
        mimeType: 'audio/wav',
        recorderType: RecordRTC.StereoAudioRecorder,
        desiredSampRate: 16000,
        numberOfAudioChannels: 1,
        disableLogs: true,
      });

      recRTCRef.current.instance.startRecording();
      setRecorder({ ...recorder, isRecording: true, blob: null, url: null });
      gaEvent('voice_recording');
    } catch (err) {
      setPermissionDenied(true);
    }
  }

  const handleStopRecording = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    recRTCRef.current.instance.stopRecording(() => {
      recRTCRef.current.stream.getTracks().forEach(track => track.stop());

      const blob = recRTCRef.current.instance.getBlob();
      const url = URL.createObjectURL(blob);

      setRecorder({ ...recorder, isRecording: false, blob, url });

      recRTCRef.current.stream = null;
      recRTCRef.current.instance = null;

      onRecordFinished && setTimeout(() => onRecordFinished(), 1000);
    });
  }

  return (
    <div className="AudioRecordButton">
      {
        permissionDenied
        && (
          <InformationModal
            title="Microphone permissions"
            descriptions={['You have not allowed this site to use microphone. Follow below steps to allow and record:']}
            instructions={['Reset your browser microphone permissions.', 'Reload page.', 'Allow microphone permissions once prompted by browser.']}
            actionCallBack={() => setPermissionDenied(false)}
          />
        )
      }

      <ButtonBox
        className="ButtonBox-AudioPlayButton-icon"
        text={<i className={recorder.isRecording ? 'fa fa-stop' : 'fas fa-microphone'} />}
        onClick={e => recorder.isRecording ? handleStopRecording(e) : handleStartRecording(e)}
      />

      {
        recorder.url ? (
          <AudioPlayButton src={recorder.url} onPlayFinished={() => onPlayFinished()} />
        ) : (
          <div data-tip data-for="No-Recording-Play" className="No-Recording">
            <i className="fa fa-play Icon" />
            <ReactTooltip id="No-Recording-Play" place="bottom" type="info" effect="solid">
              Please record yourself in order to play back the audio.
            </ReactTooltip>
          </div>
        )
      }

      {
        recorder.blob ? (
          <AudioAnalyzeButton language={language} blob={recorder.blob} url={recorder.url} text={text} />
        ) : (
          <div data-tip data-for="No-Recording-Analyze" className="No-Recording">
            <i className="fa-solid fa-language Icon" />
            <ReactTooltip id="No-Recording-Analyze" place="bottom" type="info" effect="solid">
              Please record yourself in order to get pronunciation feedback.
            </ReactTooltip>
          </div>
        )
      }
    </div>
  )
}

export default AudioRecordButton;
