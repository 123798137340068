import React from 'react';

import './TableEntryBox.css';
import QuestionBox
  from '../../Quiz/QuestionBox/QuestionBox';
import { COLORS }
  from '../../../Style/Style';

import { sanitizeHtmlString } from '../../../Util/Helper/SanitizeUtil';

const TableEntryBox = ({tableEntry, onClick, creation}) => {
  return (
    <div className="TableEntryBox"
      onClick={onClick}
      style={
        onClick && {
          cursor: "pointer"
        }
      }>
      
      {
        tableEntry &&
        <QuestionBox question={tableEntry} creation={creation}>
          <div
            className="ql-editor ql-editor-inline"
            dangerouslySetInnerHTML={{ __html: sanitizeHtmlString(tableEntry.text) }}
          />
        </QuestionBox>
      }
    </div>
  );
}

export default TableEntryBox;
